import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { Icon, Menu } from "semantic-ui-react";

import { selectClientColor, selectDocumentExportHtmlByDocumentId } from "../selectors/entity";

interface Props {
  documentId: string;
  school: string;
}

const DocumentDownloadButton: FunctionComponent<Props> = ({
  documentId,
  school,
}) => {
  const color = useSelector(selectClientColor);
  const documentExportHtml = useSelector((state) =>
    selectDocumentExportHtmlByDocumentId(state, documentId),
  );
  let url: string;

  if (!documentExportHtml) {
    return null;
  }

  if (documentExportHtml.pdfFilePath) {
    // TODO deprecated
    url = `https://${school}.oerwrite.nl${documentExportHtml.pdfFilePath}`;
  } else if (documentExportHtml.pdfFileUrl) {
    url = documentExportHtml.pdfFileUrl;
  } else {
    return null;
  }

  return (
    <Menu.Item as="a" href={url} style={{ color }} target="_blank">
      <Icon name="download" /> PDF
    </Menu.Item>
  );
};

export default DocumentDownloadButton;
