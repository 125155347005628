import React, { createRef, Fragment, PureComponent } from "react";
import { Container, Grid, Ref, Sidebar, Sticky } from "semantic-ui-react";

import withScrollToHash from "../../hocs/withScrollToHash";
import DocumentStructureLib from "../../lib/document-structure-lib";
import { Desktop, Mobile } from "../Media";
import Chapter from "./Chapter";
import ChapterList from "./ChapterList";
import ChapterListSidebar from "./ChapterListSidebar";

interface Props {
  documentId: string;
  documentStructure: DocumentStructureLib;
  school: string;
}

interface State {
  indexChapterActive: number;
  timeoutTill: number;
}

class DocumentView extends PureComponent<Props, State> {
  public contextRef = createRef<HTMLElement>();

  public state: State = {
    indexChapterActive: 0,
    timeoutTill: 0,
  };

  private handleIsInsideChapter = (indexChapterActive: number) => {
    const { timeoutTill } = this.state;

    if (Date.now() > timeoutTill) {
      this.setState({ indexChapterActive });
    }
  };

  private handleChangeIndexChapterActive = (indexChapterActive: number) => {
    const timeoutTill = Date.now() + 500;

    this.setState({
      indexChapterActive,
      timeoutTill,
    });
  };

  public render(): React.ReactNode {
    const { documentId, documentStructure, school } = this.props;
    const { indexChapterActive } = this.state;
    const documentStructureChapters = documentStructure.getStructure();

    return (
      <Fragment>
        <Mobile>
          <ChapterListSidebar
            documentId={documentId}
            documentStructure={documentStructure}
            indexChapterActive={indexChapterActive}
            onChangeIndexChapterActive={this.handleChangeIndexChapterActive}
          />
        </Mobile>

        <Sidebar.Pusher>
          <Ref innerRef={this.contextRef}>
            <Container>
              <Grid>
                <Grid.Row>
                  <Desktop>
                    <Grid.Column computer={5}>
                      <Sticky
                        context={this.contextRef}
                        offset={64}
                        styleElement={{
                          maxHeight: "calc(100% - 64px - 14px)",
                          overflowY: "auto",
                        }}
                      >
                        <ChapterList
                          documentId={documentId}
                          documentStructureChapters={documentStructureChapters}
                          indexChapterActive={indexChapterActive}
                          onChangeIndexChapterActive={
                            this.handleChangeIndexChapterActive
                          }
                        />
                      </Sticky>
                    </Grid.Column>
                  </Desktop>

                  <Grid.Column computer={11} tablet={16} mobile={16}>
                    <div
                      className={`document-content ${school}`}
                      style={{ marginBottom: 20 }}
                    >
                      {documentStructureChapters.map((chapter, index) => (
                        <Chapter
                          key={index}
                          chapter={chapter}
                          index={index}
                          onIsInside={this.handleIsInsideChapter}
                        />
                      ))}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Ref>
        </Sidebar.Pusher>
      </Fragment>
    );
  }
}

export default withScrollToHash(DocumentView);
