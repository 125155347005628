import { createSelector } from "reselect";

import { Client } from "../enums/client";
import { State } from "../reducers";

export const selectUi = (state: State) => state.ui;
export const selectClient = (state: State) => state.ui.client;
export const selectIsSidebarOpen = (state: State) => state.ui.isSidebarOpen;

export const clientNames: Partial<Record<Client, string>> = {
  [Client.Avans]: "Avans Hogeschool",
  [Client.Inholland]: "Inholland",
  [Client.Hva]: "Hogeschool van Amsterdam",
  [Client.Hu]: "Hogeschool Utrecht",
  [Client.Hr]: "Hogeschool Rotterdam",
  [Client.Ru]: "Radboud Universiteit",
  [Client.Zuyd]: "Zuyd",
  [Client.Han]: "Han",
  [Client.Demo]: "Demo",
};

export const selectClientName = createSelector(
  selectClient,
  (client) => clientNames[client],
);
