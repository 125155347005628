import { Reducer } from "redux";
import { entitiesReducer, ReduxQueryAction } from "redux-query";
import { Client } from "../models/client";

import DocumentStructureLib from "../lib/document-structure-lib";
import { Attachment } from "../models/attachment";
import { DocumentExportHtml } from "../models/document-export-html";
import { Image } from "../models/image";
import { PublicDocument } from "../models/public-document";

export interface Entities {
  attachment: { [id: string]: Attachment };
  attachmentIds: { [documentId: string]: string[] };
  client: Client;
  document: { [id: string]: PublicDocument };
  documentStructure: { [documentId: string]: DocumentStructureLib };
  documentExportHtml: { [id: string]: DocumentExportHtml };
  image: { [id: string]: Image };
}

const entity = entitiesReducer as Reducer<Entities, ReduxQueryAction<Entities>>;

export default entity;
