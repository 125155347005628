import queryString from "query-string";
import { QueryConfig } from "redux-query";

import env from "../constants";
import DocumentStructureLib from "../lib/document-structure-lib";
import { transformResponse } from "../lib/query-lib";
import { DocumentExportHtml } from "../models/document-export-html";
import { Entities } from "../reducers/entity";

const mergeObjects = (oldValue, newValue) => ({
  ...oldValue,
  ...newValue,
});

export const fetchAllDocumentExportHtmlByDocumentId = (
  client: string,
  documentId: string,
): QueryConfig => {
  const url = `${
    env(client).ApiUrl
  }/api/public/documents/${documentId}/document_export_htmls`;
  const query = {
    include: ["attachments", "images"],
    per_page: "1",
    sort: "-created_at",
  };

  return {
    url: queryString.stringifyUrl({ query, url }, { arrayFormat: "bracket" }),
    transform: (body): Partial<Entities> => {
      const data = transformResponse<DocumentExportHtml[]>(
        body,
        "document_export_html",
      );
      const attachment = {};
      const attachmentIds = { [documentId]: [] };
      const documentExportHtml = {};
      const documentStructure = {};
      const image = {};

      if (Array.isArray(data) && data.length) {
        const { attachments, images, ...entity } = data[0];

        if (Array.isArray(attachments)) {
          attachments.forEach((a) => (attachment[a.id] = a));
          attachmentIds[documentId] = attachments.map((a) => a.id);
        }

        if (Array.isArray(images)) {
          images.forEach((i) => (image[i.id] = i));
        }

        documentExportHtml[entity.documentId] = entity;
        documentStructure[entity.documentId] = new DocumentStructureLib(
          entity as DocumentExportHtml,
        );
      }

      return {
        attachment,
        attachmentIds,
        documentExportHtml,
        documentStructure,
        image,
      };
    },
    update: {
      attachment: mergeObjects,
      attachmentIds: mergeObjects,
      documentExportHtml: mergeObjects,
      documentStructure: mergeObjects,
      image: mergeObjects,
    },
  };
};
