import React, { Fragment, FunctionComponent, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router";

const ScrollToTop: FunctionComponent<RouteComponentProps> = ({
  children,
  location: { pathname },
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (children) {
    return <Fragment>{children}</Fragment>;
  }

  return null;
};

export default withRouter(ScrollToTop);
