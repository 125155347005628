import React, { FunctionComponent, useState } from "react";
import { Icon, Popup } from "semantic-ui-react";

interface Props {
  id: string;
}

const HeadingAnchor: FunctionComponent<Props> = ({ id }) => {
  const defaultPopupContent = "Kopieer link";
  const [popupContent, setPopupContent] = useState(defaultPopupContent);
  const handleClickAnchor = () => {
    const selection =
      document.getSelection().rangeCount > 0 &&
      document.getSelection().getRangeAt(0);
    const textAreaElement = document.createElement("textarea");

    textAreaElement.value = `${window.location.origin}${window.location.pathname}#${id}`;
    textAreaElement.setAttribute("readonly", "");
    textAreaElement.style.position = "absolute";
    textAreaElement.style.left = "-9999px";

    document.body.appendChild(textAreaElement);

    textAreaElement.select();
    document.execCommand("copy");
    document.body.removeChild(textAreaElement);

    if (selection) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selection);
    }

    setPopupContent("Link gekopieerd!");
  };
  const handleClosePopup = () => {
    setPopupContent(defaultPopupContent);
  };

  return (
    <Popup
      content={popupContent}
      on="hover"
      popperDependencies={[popupContent]}
      position="bottom center"
      trigger={
        <span style={{ marginLeft: "0.25em" }} onClick={handleClickAnchor}>
          <Icon fitted={true} link={true} name="linkify" size="small" />
        </span>
      }
      onClose={handleClosePopup}
    />
  );
};

export default HeadingAnchor;
