import { InputBase, makeStyles } from "@material-ui/core";
import React, { FunctionComponent, useEffect, useState } from "react";
import useDebounce from "../hooks/useDebounce";

interface Props {
  onSearch: (searchTerm: string) => void;
  fullscreen?: boolean;
  onClose?: () => void;
}

const DEBOUNCE_TIME = 500;

const ICON = {
  search: require("../images/search_icon.svg"),
  cancel: require("../images/cancel_icon.svg"),
  back: require("../images/back_icon.svg"),
};

const useStyles = makeStyles({
  search: {
    borderBottom: "1px solid #E9ECEF",
    display: "flex",
    alignItems: "center",
    height: "48px",
    paddingLeft: 16,
    paddingRight: 16,
    flex: "0 0 auto",
  },
  searchIcon: {
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  backIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    flex: 1,
    color: "rgba(4, 16, 26, 0.87)",
    fontSize: "16px",
    marginLeft: 14,
    marginRight: 14,
  },
});

const SearchTopToolbar: FunctionComponent<Props> = ({
  onSearch,
  fullscreen,
  onClose,
}) => {
  const classes = useStyles();

  const [searchTerm, setSearchTerm] = useState("");
  const debouncedValue = useDebounce(searchTerm, DEBOUNCE_TIME);

  useEffect(() => {
    if (debouncedValue) {
      onSearch(debouncedValue);
    }
  }, [debouncedValue]);

  // when the search term is empty reset the form
  useEffect(() => {
    if (!searchTerm) {
      onSearch("");
    }
  }, [searchTerm]);

  const handleOnChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className={classes.search}>
      {fullscreen ? (
        <div className={classes.backIcon} onClick={onClose}>
          <img src={ICON.back} />
        </div>
      ) : (
        <div className={classes.searchIcon}>
          <img src={ICON.search} />
        </div>
      )}

      <InputBase
        className={classes.input}
        autoFocus
        placeholder="Zoeken..."
        value={searchTerm}
        onChange={handleOnChange}
      />
      {searchTerm && (
        <img src={ICON.cancel} onClick={() => setSearchTerm("")} />
      )}
    </div>
  );
};

export default SearchTopToolbar;
