import { Box } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Button } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { selectClientColor } from "../../selectors/entity";

interface Props {
  academies: string[];
  selected: string;
  onClick: (id: string) => void;
}

interface StyleProps {
  color: string;
}

const useStyles = makeStyles({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    gap: "4px",
    marginBottom: "10px",
  },
  box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "105px",
    minHeight: "67px",
    border: "1px solid #9B9B9B",
    borderRadius: "4px",
    padding: "8px",
    cursor: "pointer",
    textAlign: "center",
  },
  selected: ({ color }: StyleProps) => ({
    border: `2px solid ${color}`,
  }),
  more: {
    width: "100%",
    textAlign: "center",
    marginBottom: 5,
    marginTop: -10,
  },
});

const Container = ({ academies, selected, onClick }: Props) => {
  const color = useSelector(selectClientColor);
  const classes = useStyles({ color });
  const [currentAcademies, setCurrentAcademies] = useState([]);

  const handleMoreAcademies = () => {
    setCurrentAcademies(academies);
  };
  if (!academies) {
    return null;
  }
  useEffect(() => {
    if (academies.length > 14) {
      setCurrentAcademies(academies.slice(0, 14));
    } else {
      setCurrentAcademies(academies);
    }
  }, []);

  return (
    <>
      <div className={classes.container}>
        {currentAcademies.map((academy) => (
          <Box
            className={`${classes.box} ${selected === academy ? classes.selected : ""
              }`}
            key={academy}
            onClick={() => onClick(academy)}
          >
            {academy}
          </Box>
        ))}
      </div>
      {currentAcademies.length !== academies.length && (
        <div className={classes.more}>
          <Button className="inverted" onClick={handleMoreAcademies}>
            <Button.Content style={{ color }}>Meer faculteiten</Button.Content>
          </Button>
        </div>
      )}
    </>
  );
};

export default Container;
