import { ActionType, createAction } from "typesafe-actions";

import { Client } from "../enums/client";

export enum ActionTypes {
  SET_CLIENT = "@ui/SET_CLIENT",
  SET_IS_SIDEBAR_OPEN = "@ui/SET_IS_SIDEBAR_OPEN",
}

export const setClient = createAction(ActionTypes.SET_CLIENT)<Client>();
export const setIsSidebarOpen = createAction(ActionTypes.SET_IS_SIDEBAR_OPEN)<
  boolean
>();

export type SetClient = ActionType<typeof setClient>;
export type SetIsSidebarOpen = ActionType<typeof setIsSidebarOpen>;

export type Actions = SetClient | SetIsSidebarOpen;
