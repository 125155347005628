import queryString from "query-string";
import { QueryConfig } from "redux-query";

import env from "../constants";
import { transformResponse } from "../lib/query-lib";
import { PublicDocument } from "../models/public-document";
import { Entities } from "../reducers/entity";

export const fetchAllDocument = (client: string): QueryConfig => {
  const url = `${env(client).ApiUrl}/api/public/documents`;
  const query = {
    include: ["programmes"],
    per_page: "1000",
  };

  return {
    url: queryString.stringifyUrl({ query, url }, { arrayFormat: "bracket" }),
    transform: (body): Partial<Entities> => {
      const data = transformResponse<PublicDocument[]>(body, "document");
      const entities = {};

      if (data) {
        data.forEach((document) => (entities[document.id] = document));
      }

      return {
        document: entities,
      };
    },
    update: {
      document: (oldValue, newValue) => ({
        ...oldValue,
        ...newValue,
      }),
    },
  };
};
