import { createSelector } from "reselect";
import { Client } from "../models/client";

import { State } from "../reducers";

const selectId = (_, id: string) => id;

const DEFAULT_COLOR = "#1C69FF";

export const selectEntities = (state: State) => state.entity;
export const selectClient = (state: State) => state.entity.client || {}

export const selectClientColor = createSelector(
  selectClient,
  (client: Client) => client.publicReaderSetting?.primaryColor || DEFAULT_COLOR,
)

const selectAttachment = (state: State) => state.entity.attachment || {};
const selectDocument = (state: State) => state.entity.document || {};
const selectDocumentExportHtml = (state: State) =>
  state.entity.documentExportHtml || {};
const selectDocumentStructure = (state: State) =>
  state.entity.documentStructure || {};
const selectImage = (state: State) => state.entity.image || {};

const selectAttachmentIds = createSelector(
  selectEntities,
  (entities) => entities.attachmentIds || {},
);
const selectAttachmentIdsByDocumentId = createSelector(
  selectAttachmentIds,
  selectId,
  (attachmentIds, documentId) => attachmentIds[documentId] || [],
);
export const selectAttachmentsByDocumentId = createSelector(
  selectAttachment,
  selectAttachmentIdsByDocumentId,
  (attachment, attachmentIdsByDocumentId) =>
    attachmentIdsByDocumentId.map((id) => attachment[id]),
);

export const selectDocumentById = createSelector(
  selectDocument,
  selectId,
  (document, documentId) => document[documentId],
);
export const selectDocumentArray = createSelector(selectDocument, (document) =>
  Object.values(document).sortBy("title"),
);

export const selectDocumentExportHtmlByDocumentId = createSelector(
  selectDocumentExportHtml,
  selectId,
  (documentExportHtml, documentId) => documentExportHtml[documentId],
);

export const selectVersionsByDocumentId = createSelector(
  selectDocumentExportHtml,
  selectId,
  (documentExportHtml, documentId) =>
    documentExportHtml[documentId].versions || [],
);

export const selectDocumentStructureByDocumentId = createSelector(
  selectDocumentStructure,
  selectId,
  (documentStructure, documentId) => documentStructure[documentId],
);

export const selectImageById = createSelector(
  selectImage,
  selectId,
  (images, id) => images[id],
);
