import { Actions, ActionTypes } from "../actions/ui";
import { Client } from "../enums/client";

interface State {
  client: Client;
  isSidebarOpen: boolean;
}

const initialState: State = {
  client: undefined,
  isSidebarOpen: false,
};

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.SET_CLIENT:
      return {
        ...state,
        client: action.payload,
      };
    case ActionTypes.SET_IS_SIDEBAR_OPEN:
      return {
        ...state,
        isSidebarOpen: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
