import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Icon, Menu } from "semantic-ui-react";
import { Version } from "../../../models/version";

import { selectVersionsByDocumentId } from "../../../selectors/entity";

interface Props {}

const formatDateTimeFromString = (dateString) => {
  const formattedDate = new Date(dateString).toLocaleDateString("nl-NL", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const formattedTime = new Date(dateString).toLocaleTimeString("nl-NL", {
    hour12: false,
    hour: "numeric",
    minute: "numeric",
  });

  return `${formattedDate}, ${formattedTime}`;
};

const VersionList: FunctionComponent<Props> = () => {
  const { documentId } = useParams();

  const versions: Version[] = useSelector((state) =>
    selectVersionsByDocumentId(state, documentId),
  );
  const oldVersions = versions.slice(1);

  if (!oldVersions.length) {
    return null;
  }

  return (
    <>
      <Menu.Item header={true}>Oudere versies</Menu.Item>
      {oldVersions.map((version) => (
        <Menu.Item
          key={version.id}
          as="a"
          className="borderless chapter-item"
          href={version.url}
          target="_blank"
        >
          <Icon name="external" />
          {formatDateTimeFromString(version.createdAt)}
        </Menu.Item>
      ))}
    </>
  );
};

export default VersionList;
