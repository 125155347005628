import queryString from "query-string";
import { QueryConfig } from "redux-query";

import env from "../constants";
import { transformResponse } from "../lib/query-lib";
import { Client } from "../models/client";
import { Entities } from "../reducers/entity";

export const fetchClient = (client: string): QueryConfig => {
  const url = `${env(client).ApiUrl}/api/public/clients?include[]=programmes&include[]=programme_student_titles`;

  return {
    url,
    transform: (body): Partial<Entities> => {
      const clients = transformResponse<Client[]>(body, "clients");
      const clientData = clients.find(
        (clientObject) => clientObject.name === client,
      )

      return {
        client: clientData,
      };
    },
    update: {
      client: (oldValue, newValue) => ({
        ...oldValue,
        ...newValue,
      }),
    },
  };
};
