import {
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { SearchObjectType } from "../models/search-object-type";
import SearchResult from "../models/search-result";

const ICONS = {
  content: require("../images/content_icon.svg"),
  heading: require("../images/heading_icon.svg"),
};

interface Props {
  searchResult: SearchResult;
  onClick: () => void;
}

const useStyles = makeStyles({
  primaryText: {
    color: "rgba(4, 16, 26, 0.54)",
    fontSize: "10px",
    lineHeight: "14px",
  },
  secondaryText: {
    color: "rgba(4, 16, 26, 0.87)",
    fontSize: "14px",
    lineHeight: "16px",
  },
  listItem: {
    "&:hover, &:focus": {
      backgroundColor: "rgba(28, 105, 255, 0.12) !important",
    },
  },
  listItemText: {
    marginLeft: 14,
    "& .highlight": {
      fontWeight: "bold",
    },
  },
  objectIcon: {
    alignSelf: "flex-start",
    marginTop: "6px",
  },
});

const SearchListItem: FunctionComponent<Props> = ({
  searchResult,
  onClick,
}) => {
  const classes = useStyles();

  const { objectType } = searchResult;

  return (
    <ListItem className={classes.listItem} button={true} onClick={onClick}>
      <img
        className={classes.objectIcon}
        src={
          objectType === SearchObjectType.Chapter ||
          objectType === SearchObjectType.Heading
            ? ICONS.heading
            : ICONS.content
        }
      />

      <ListItemText
        className={classes.listItemText}
        primary={
          <Typography className={classes.primaryText}>
            <span>{searchResult.nearestHeadingTitle}</span>
          </Typography>
        }
        secondary={
          <Typography className={classes.secondaryText}>
            <span
              dangerouslySetInnerHTML={{
                __html: searchResult.highlight.join("..."),
              }}
            />
          </Typography>
        }
      />
    </ListItem>
  );
};

export default SearchListItem;
