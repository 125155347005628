import { makeStyles } from "@material-ui/core";
import React, { FunctionComponent } from "react";

interface Props {
  matches: number;
}

const useStyles = makeStyles({
  toolbar: {
    borderTop: "1px solid #E9ECEF",
    height: "35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: 16,
    flex: "0 0 auto",
  },
});

const SearchBottomToolbar: FunctionComponent<Props> = ({ matches }) => {
  const classes = useStyles();

  return <div className={classes.toolbar}>{matches} overeenkomsten</div>;
};

export default SearchBottomToolbar;
