import React, { FunctionComponent, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "semantic-ui-react";

import {
  getLanguageName,
  PublicDocument,
} from "../../../models/public-document";

export interface Props {
  color: string;
  document: PublicDocument;
}

const ListItem: FunctionComponent<Props> = ({ color, document }) => {
  const [readMore, setReadMore] = useState(false);
  const buildDescription = (document: PublicDocument) => {
    const programmes = [...document.programmes];
    const firstProgramme = programmes.shift();
    return {
      text: [
        [firstProgramme?.academy, firstProgramme?.reference]
          .filter(Boolean)
          .join(" "),
        firstProgramme?.programmeStudentTitle?.name,
        firstProgramme?.name,
      ]
        .filter(Boolean)
        .join(" • "),
      more: programmes
        .map((p) =>
          [p.academy, p.reference, p.programmeStudentTitle.name, p.name]
            .filter(Boolean)
            .join(" • "),
        )
        .join(" <br/>"),
    };
  };
  const { text, more } = document && buildDescription({ ...document });
  return (
    <Table.Row>
      <Table.Cell>
        <Link className="header" to={`/${document.id}`} style={{ color }}>
          {document.title}
          {document.language !== "nl"
            ? ` (${getLanguageName(document.language)})`
            : ""}
        </Link>
        <br />
        <div className="template-name">
          {text}
          {more && (
            <a
              href={`#${document.id}_more`}
              onClick={() => setReadMore((readMore) => !readMore)}
              style={{ color }}
            >
              {" "}
              Lees verder
            </a>
          )}
        </div>
        {readMore && (
          <div
            id={`${document.id}_more`}
            className="template-name"
            dangerouslySetInnerHTML={{ __html: more }}
          />
        )}
      </Table.Cell>
      <Table.Cell textAlign="right">
        {document.academicYear ?? "Niet gedefinieerd"}
      </Table.Cell>
    </Table.Row>
  );
};

export default ListItem;
