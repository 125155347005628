import React, { FunctionComponent } from "react";
import { Visibility } from "semantic-ui-react";

import { DocumentStructureChapter } from "../../models/document-structure-chapter";
import ChapterContent from "./ChapterContent";

interface Props {
  chapter: DocumentStructureChapter;
  index: number;
  onIsInside: (index: number) => void;
}

const Chapter: FunctionComponent<Props> = ({ chapter, index, onIsInside }) => (
  <div className="chapter">
    <Visibility
      continuous={false}
      fireOnMount={true}
      once={false}
      offset={100}
      onBottomPassedReverse={() => onIsInside(index)}
      onTopPassed={() => onIsInside(index)}
      onTopPassedReverse={() => onIsInside(index)}
    >
      <ChapterContent html={chapter.content} />
    </Visibility>
  </div>
);

export default Chapter;
