import React, { FunctionComponent } from "react";
import { Container, Menu } from "semantic-ui-react";

import { useDispatch } from "react-redux";
import { setClient } from "../actions/ui";
import { Client } from "../enums/client";
import { clientNames } from "../selectors/ui";
import ClientLogo from "./ClientLogo";

interface Props {}

const ClientList: FunctionComponent<Props> = () => {
  const dispatch = useDispatch();
  const handleClick = (client: Client) => dispatch(setClient(client));

  return (
    <Container>
      <Menu vertical={true} className="client-list">
        {Object.entries(clientNames).map(([slug, name]: [Client, string]) => (
          <Menu.Item key={slug} onClick={() => handleClick(slug)}>
            <ClientLogo alt={name} client={slug} />
          </Menu.Item>
        ))}
      </Menu>
    </Container>
  );
};

export default ClientList;
