import Fuse from "fuse.js";
import React, { Fragment, FunctionComponent, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useRequests } from "redux-query-react";
import { Button, Container, Header, Table } from "semantic-ui-react";
import { DocumentFilter } from "../../models/document-filter";
import { Client } from "../../models/client";

import { PublicDocument } from "../../models/public-document";
import { fetchAllDocument } from "../../queries/document";
import { fetchClient } from "../../queries/client";
import {
  selectClient as selectClientDetails,
  selectClientColor,
  selectDocumentArray,
} from "../../selectors/entity";
import { selectClient } from "../../selectors/ui";
import ListItemDesktop from "./ListItem/Desktop";
import ListItemMobile from "./ListItem/Mobile";
import ListLoader from "./ListLoader";
import FilterBarMobile from "./FilterBar/Mobile";
import FilterBarDesktop from "./FilterBar/Desktop";
import { useMobileMediaQuery } from "../Media";

const ALL_ACADEMIES = "Alle faculteiten";
const ALL_YEARS = "Alle jaren";
const ALL_PROGRAMME_STUDENT_TITLE = "Alle niveaus";
const ALL_PROGRAMME = "Alle opleidingen";

interface Props {}

const toOption = (data: { id: string; name: string }) => ({
  value: data.id,
  text: data.name,
});
const getCurrentAcademicYear = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const startAcademycYear = new Date(currentYear, 8, 1);
  if (startAcademycYear < currentDate) {
    return `${currentYear}-${currentYear + 1}`;
  }
  return `${currentYear - 1}-${currentYear}`;
};

const sortByAcademicYear = (a: string, b: string) => {
  const [startYear] = a?.split("-") || [];
  const [endYear] = b?.split("-") || [];
  return +endYear - +startYear;
};

const sortByAcademies = (a: string, b: string) => {
  if (a < b) {
    return -1;
  }
  if (a < b) {
    return 1;
  }
  return 0;
};
const DocumentList: FunctionComponent<Props> = () => {
  const currentAcademicYear = getCurrentAcademicYear();

  const [filter, setFilter] = useState<DocumentFilter>({
    searchTerm: "",
    academicYear: currentAcademicYear,
    programmeId: ALL_PROGRAMME,
    programmeStudentTitleId: ALL_PROGRAMME_STUDENT_TITLE,
    academy: ALL_ACADEMIES,
  });
  const color = useSelector(selectClientColor);
  const school = useSelector(selectClient);
  const [{ isPending }] = useRequests([
    fetchAllDocument(school),
    fetchClient(school),
  ]);
  const client: Client = useSelector(selectClientDetails);
  const documents = useSelector(selectDocumentArray);
  const isMobile = useMobileMediaQuery();
  const { description, pageTitle } = client.publicReaderSetting || {};

  const hanldeResetYear = () => {
    setFilter({
      ...filter,
      searchTerm: "",
      academicYear: ALL_YEARS,
      programmeId: ALL_PROGRAMME,
      programmeStudentTitleId: ALL_PROGRAMME_STUDENT_TITLE,
      academy: ALL_ACADEMIES,
    });
  };
  const {
    documentsFiltered,
    uniqueAcademicYears,
    academies,
    programmeStudentTitles,
    programmes,
  } = useMemo(() => {
    const fuseOptions: Fuse.IFuseOptions<PublicDocument> = {
      shouldSort: false,
      ignoreLocation: true,
      threshold: 0,
      minMatchCharLength: 1,
      keys: [
        "title",
        "templateName",
        "programmes.academy",
        "programmes.reference",
        "programmes.programmeStudentTitle.name",
      ],
    };
    const fuse = new Fuse(documents, fuseOptions);

    const searchResults =
      filter.searchTerm.length === 0
        ? documents
        : fuse.search(filter.searchTerm).map((result) => result.item);

    const uniqueAcademicYears = [
      ALL_YEARS,
      ...searchResults
        .map((document) => document.academicYear)
        .filter(Boolean)
        .unique()
        .sort(sortByAcademicYear),
    ];
    if (uniqueAcademicYears.length === 1 && filter.academicYear !== ALL_YEARS) {
      uniqueAcademicYears.push(filter.academicYear);
    }

    const academies = [ALL_ACADEMIES].concat(
      ...(client.programmes
        ? client.programmes
            .map((p) => p.academy)
            .unique()
            .sort(sortByAcademies)
        : []),
    );
    const programmeStudentTitles = [
      { value: ALL_PROGRAMME_STUDENT_TITLE, text: ALL_PROGRAMME_STUDENT_TITLE },
    ].concat(
      ...(client.programmeStudentTitles
        ? client.programmeStudentTitles.map(toOption)
        : []),
    );
    const programmes = [{ value: ALL_PROGRAMME, text: ALL_PROGRAMME }].concat(
      ...(client.programmes ? client.programmes.map(toOption) : []),
    );

    const filterByAcademy = (document: PublicDocument) =>
      filter.academy === ALL_ACADEMIES
        ? true
        : document.programmes.find((p) => p.academy === filter.academy);

    const filterByAcademyYear = (document: PublicDocument) =>
      filter.academicYear === ALL_YEARS
        ? true
        : document.academicYear === filter.academicYear;

    const filterByLevel = (document: PublicDocument) =>
      filter.programmeStudentTitleId === ALL_PROGRAMME_STUDENT_TITLE
        ? true
        : document.programmes.find(
            (p) =>
              p.programmeStudentTitle.id === filter.programmeStudentTitleId,
          );
    const filterByCourse = (document: PublicDocument) =>
      filter.programmeId === ALL_PROGRAMME
        ? true
        : document.programmes.find((p) => p.id === filter.programmeId);

    return {
      uniqueAcademicYears,
      academies,
      programmeStudentTitles,
      programmes,
      documentsFiltered: searchResults
        .filter(filterByAcademy)
        .filter(filterByAcademyYear)
        .filter(filterByLevel)
        .filter(filterByCourse)
        .sort((a, b) => sortByAcademicYear(a.academicYear, b.academicYear)),
    };
  }, [documents, filter, client, currentAcademicYear]);
  return (
    <Container style={{ paddingBottom: 14 }}>
      {isPending ? (
        <ListLoader />
      ) : (
        <>
          <Header as="h1">
            {pageTitle}
            <Header.Subheader>
              {documentsFiltered.length} documenten
            </Header.Subheader>
          </Header>
          <div
            className="documents-description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          {isMobile ? (
            <FilterBarMobile
              filter={filter}
              onChange={setFilter}
              academies={academies}
              uniqueAcademicYears={uniqueAcademicYears}
              programmes={programmes}
              programmeStudentTitles={programmeStudentTitles}
            />
          ) : (
            <FilterBarDesktop
              filter={filter}
              onChange={setFilter}
              academies={academies}
              uniqueAcademicYears={uniqueAcademicYears}
              programmes={programmes}
              programmeStudentTitles={programmeStudentTitles}
            />
          )}
          <Table unstackable={true}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Document</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  Academisch jaar
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {documentsFiltered.map((document) => (
                <Fragment key={document.id}>
                  {isMobile ? (
                    <ListItemMobile color={color} document={document} />
                  ) : (
                    <ListItemDesktop color={color} document={document} />
                  )}
                </Fragment>
              ))}
            </Table.Body>
          </Table>
          {documentsFiltered.length === 0 && (
            <>
              <div
                style={{
                  textAlign: "center",
                  marginTop: 30,
                }}
              >
                <p>Geen documenten voldoen aan deze criteria</p>
                <Button className="inverted" onClick={hanldeResetYear}>
                  <Button.Content style={{ color }}>
                    Bekijk alle documenten
                  </Button.Content>
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default DocumentList;
