import React, { CSSProperties, FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import DocumentStructureLib from "../../lib/document-structure-lib";
import { selectDocumentStructureByDocumentId } from "../../selectors/entity";
import HeadingAnchor from "./HeadingAnchor";

interface Props {
  level: number;
  text: string;
  id?: string
}

const RawHeading = ({ children, level, ...otherProps }) =>
  React.createElement(`h${level}`, otherProps, children);

const Heading: FunctionComponent<Props> = ({ level, text, id }) => {
  const { documentId } = useParams();
  const documentStructure = useSelector((state) =>
    selectDocumentStructureByDocumentId(state, documentId),
  );
  const cssRule = documentStructure.getCssRuleForElement(`h${level}`);
  const slug: string = DocumentStructureLib.slugify(text);
  const style: CSSProperties = {};

  if (cssRule) {
    style.color = cssRule.style.color;
  }

  return (
    <RawHeading level={level} style={style}>
      <a className="anchor" id={id} />
      <a className="anchor" id={slug} />
      {text}
      <HeadingAnchor id={slug} />
    </RawHeading>
  );
};

export default Heading;
