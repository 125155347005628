import { List, makeStyles } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import SearchResult from "../models/search-result";
import SearchListItem from "./SearchListItem";

interface Props {
  searchResults: SearchResult[];
  onSelectItem?: (item: SearchResult) => void;
  buildLink?: (item: SearchResult) => string;
}

const useStyles = makeStyles({
  list: {
    overflowY: "auto",
  },
});

const ConditionalWrap = ({ condition, wrap, children }) =>
  condition ? wrap(children) : <>{children}</>;

const SearchList: FunctionComponent<Props> = ({
  searchResults,
  onSelectItem,
  buildLink,
}) => {
  const classes = useStyles();

  const handleOnClick = (item) => {
    if (onSelectItem) {
      onSelectItem(item);
    }
  };

  return (
    <List className={classes.list}>
      {searchResults.map((result) => (
        <ConditionalWrap
          key={result.id}
          condition={buildLink}
          wrap={(children) => <a href={buildLink(result)}>{children}</a>}
        >
          <SearchListItem
            searchResult={result}
            onClick={() => handleOnClick(result)}
          />
        </ConditionalWrap>
      ))}
    </List>
  );
};

export default SearchList;
