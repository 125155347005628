import { makeStyles } from "@material-ui/core";
import React, { FunctionComponent } from "react";

interface Props {}

const NO_RESULTS_IMAGE = require("../images/no_results.png");

const useStyles = makeStyles({
  container: {
    paddingTop: 24,
    paddingBottom: 24,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  primaryText: {
    fontSize: "20px",
    lineHeight: "24px",
    color: "rgba(4, 16, 26, 0.87)",
    marginTop: 24,
  },
  secondaryText: {
    fontSize: "14px",
    lineHeight: "16px",
    color: "rgba(4, 16, 26, 0.54)",
    marginTop: 8,
  },
});

const NoMatches: FunctionComponent<Props> = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <img src={NO_RESULTS_IMAGE} />
      <div className={classes.primaryText}>Geen overeenkomsten</div>
      <div className={classes.secondaryText}>
        Check de spelling of probeer een ander trefwoord
      </div>
    </div>
  );
};

export default NoMatches;
