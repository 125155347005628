import React from "react";
import { Container, Grid, Placeholder, Segment } from "semantic-ui-react";
import { Desktop } from "../Media";

export default () => (
  <Container>
    <Grid>
      <Grid.Row>
        <Desktop>
          <Grid.Column computer={5}>
            <Segment>
              <Placeholder>
                <Placeholder.Paragraph>
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Paragraph>
              </Placeholder>
            </Segment>
          </Grid.Column>
        </Desktop>

        <Grid.Column computer={11} tablet={16} mobile={16}>
          <Placeholder>
            <Placeholder.Header>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
);
