import React, { Fragment, FunctionComponent, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Accordion, Menu } from "semantic-ui-react";

import { setIsSidebarOpen } from "../../../actions/ui";
import DocumentStructureLib from "../../../lib/document-structure-lib";
import { Client } from "../../../models/client";
import { DocumentStructureChapter } from "../../../models/document-structure-chapter";
import { State } from "../../../reducers";
import { selectClientColor } from "../../../selectors/entity";
import {
  selectClient,
  selectIsSidebarOpen,
} from "../../../selectors/ui";
import DocumentDownloadButton from "../../DocumentDownloadButton";
import DocumentSearchButton from "../../DocumentSearchButton";
import { Mobile } from "../../Media";
import AttachmentList from "./AttachmentList";
import ChapterItem from "./ChapterItem";
import VersionList from "./VersionList";

interface Props {
  borderless?: boolean;
  documentId: string;
  documentStructureChapters: DocumentStructureChapter[];
  indexChapterActive: number;
  onChangeIndexChapterActive: (index: number) => void;
}

interface StateProps {
  clientColor: string;
  isSidebarOpen: boolean;
  school: string;
  client: Client;
}

interface DispatchProps {
  setIsSidebarOpen: typeof setIsSidebarOpen;
}

type ChapterListProps = Props & StateProps & DispatchProps;

const ChapterList: FunctionComponent<ChapterListProps> = ({
  borderless = false,
  clientColor,
  documentId,
  documentStructureChapters,
  indexChapterActive,
  isSidebarOpen,
  onChangeIndexChapterActive,
  school,
  setIsSidebarOpen,
  client,
}) => {
  const styleAccordion = useMemo(() => {
    if (borderless) {
      return {
        borderLeft: 0,
        borderRight: 0,
        borderTop: 0,
        borderRadius: 0,
        boxShadow: "none",
      };
    }

    return {};
  }, [borderless]);
  const handleClickChapter = (event, itemProps): void => {
    const chapter = documentStructureChapters[itemProps.index];
    const id = DocumentStructureLib.slugify(chapter.title);
    const elementId = chapter.titleId === undefined ? id : chapter.titleId;
    const element = document.getElementById(elementId);

    if (element) {
      element.scrollIntoView();
      onChangeIndexChapterActive(itemProps.index);
    }
  };
  const closeSidebar = (): void => {
    if (isSidebarOpen) {
      setIsSidebarOpen(false);
    }
  };

  return (
    <Accordion as={Menu} fluid={true} style={styleAccordion} vertical={true}>
      {client.elasticSearchEnabled && (
        <Mobile>
          <DocumentSearchButton
            documentId={documentId}
            school={school}
            fullscreen
            onClick={closeSidebar}
          />
        </Mobile>
      )}
      <Menu.Item header={true}>Inhoudsopgave</Menu.Item>
      {documentStructureChapters.map(
        (chapter: DocumentStructureChapter, index: number) => (
          <ChapterItem
            key={index}
            chapter={chapter}
            colorIndicator={clientColor}
            chapterIndex={index}
            isChapterActive={indexChapterActive === index}
            onClickChapter={handleClickChapter}
            onClickParagraph={closeSidebar}
          />
        ),
      )}
      <AttachmentList />
      <VersionList />
      <Mobile>
        <DocumentDownloadButton documentId={documentId} school={school} />
      </Mobile>
    </Accordion>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  clientColor: selectClientColor(state),
  isSidebarOpen: selectIsSidebarOpen(state),
  school: selectClient(state),
  client: selectClient(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators({ setIsSidebarOpen }, dispatch);

export default connect<StateProps, DispatchProps, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(ChapterList);
