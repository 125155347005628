import { DocumentExportHtml } from "../models/document-export-html";
import { DocumentStructureChapter } from "../models/document-structure-chapter";

const MIME_TYPE_HTML = "text/html";

export default class DocumentStructureLib {
  /**
   * @link https://gist.github.com/codeguy/6684588#gistcomment-3361909
   * @param text
   */
  public static slugify = (text: string): string =>
    text
      .toString()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .trim()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "")
      .replace(/--+/g, "-");

  private dom: Document;
  private readonly documentStructure: DocumentStructureChapter[];

  public constructor(private documentExportHtml: DocumentExportHtml) {
    this.initializeDom();

    this.documentStructure = this.parseDocumentStructure();
  }

  public getCssRuleForElement = (elementName: string): CSSStyleRule | null => {
    const element = document.createElement(elementName);
    const styleSheet = this.getStyleSheet();

    for (const cssRuleKey in styleSheet.cssRules) {
      const cssRule = styleSheet.cssRules[cssRuleKey];

      if (cssRule instanceof CSSStyleRule) {
        if (element.matches(cssRule.selectorText)) {
          return cssRule;
        }
      }
    }
  };

  public getStructure = (): DocumentStructureChapter[] =>
    this.documentStructure;

  public getStyleSheet = (): CSSStyleSheet => {
    return this.dom.querySelector("style").sheet as CSSStyleSheet;
  };

  private initializeDom = (): void => {
    const parser = new DOMParser();
    let content: string;

    if (typeof this.documentExportHtml.htmlBody === "string") {
      content = this.documentExportHtml.htmlBody;
    } else {
      content = this.documentExportHtml.htmlBody.content;
    }

    this.dom = parser.parseFromString(content, MIME_TYPE_HTML);
  };

  private getInnerTextTrimmed = (element: HTMLElement): string => {
    return element.innerText.trim().replace(/\s+/, " ");
  };

  private parseDocumentStructure = (): DocumentStructureChapter[] => {
    const structure: DocumentStructureChapter[] = [];
    const frontPage = this.parseFrontPage();
    const chapterElements: NodeListOf<HTMLDivElement> = this.dom.querySelectorAll(
      "div.chapter",
    );

    if (frontPage && frontPage.content) {
      structure.push(frontPage);
    }

    chapterElements.forEach((chapterElement) => {
      const titleElement = chapterElement.querySelector("h1");
      const headingElements = chapterElement.querySelectorAll("h1,h2,h3,h4");
      const paragraphElements = chapterElement.querySelectorAll("h2");
      const paragraphs = Array.from(paragraphElements).map(
        this.getInnerTextTrimmed,
      );
      const titleId = (titleElement.querySelector('a') || {})["name"]
      titleElement.id = titleId
      const title = this.getInnerTextTrimmed(titleElement);
      let content: string;

      // Modify DOM and retrieve content afterwards
      headingElements.forEach((element: HTMLHeadingElement) => {
        element.innerText = this.getInnerTextTrimmed(element);
      });
      content = chapterElement.innerHTML;

      structure.push({
        title,
        titleId,
        paragraphs,
        content,
      });
    });

    return structure;
  };

  private parseFrontPage = (): DocumentStructureChapter => {
    const frontPageElement: HTMLDivElement = this.dom.querySelector(
      "div.frontpage",
    );

    if (frontPageElement) {
      let content = frontPageElement.innerHTML;
      const frontPageFooterElement: HTMLDivElement = this.dom.querySelector(
        "[name='frontpage_footer']",
      );
      const id = DocumentStructureLib.slugify("Voorblad");

      if (frontPageFooterElement) {
        content += frontPageFooterElement.innerHTML;
      }

      return {
        title: "Voorblad",
        paragraphs: [],
        content: `
          <div class="frontpage">
            <a class="anchor" id="${id}"></a>
            ${content}
          </div>
        `,
      };
    }
  };
}
