import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { useRequest } from "redux-query-react";

import { fetchAllDocumentExportHtmlByDocumentId } from "../../queries/document-export-html";
import { fetchClient } from "../../queries/client";
import { selectDocumentStructureByDocumentId } from "../../selectors/entity";
import { selectClient } from "../../selectors/ui";
import DocumentSpinner from "./DocumentSpinner";
import DocumentView from "./DocumentView";

interface Params {
  documentId: string;
}

interface Props extends RouteComponentProps<Params> {}

const Container: FunctionComponent<Props> = ({ match }) => {
  const { documentId } = match.params;
  const school = useSelector(selectClient);
  const documentStructure = useSelector((state) =>
    selectDocumentStructureByDocumentId(state, documentId),
  );
  useRequest(fetchClient(school))
  useRequest(fetchAllDocumentExportHtmlByDocumentId(school, documentId));

  if (!documentStructure) {
    return <DocumentSpinner />;
  }

  return (
    <DocumentView
      documentId={documentId}
      documentStructure={documentStructure}
      school={school}
    />
  );
};

export default Container;
