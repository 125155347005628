Array.prototype.sortBy = function <T>(key: string): T[] {
  const getValue = (obj: T, path: string): any => {
    let value: any = obj;

    path.split(".").forEach((pathPart) => (value = value[pathPart]));

    return value;
  };

  return this.sort((a: T, b: T) => {
    let valueA = getValue(a, key);
    let valueB = getValue(b, key);

    if (typeof valueA === "number" && typeof valueB === "number") {
      // Don't change anything
    } else {
      valueA = valueA.toString().toLowerCase();
      valueB = valueB.toString().toLowerCase();
    }

    if (valueA > valueB) {
      return 1;
    } else if (valueA < valueB) {
      return -1;
    } else {
      return 0;
    }
  });
};

Array.prototype.unique = function <T>(key?: string): T[] {
  if (key !== undefined) {
    return this.filter(
      (value, index, self) =>
        self.map((i) => i[key]).indexOf(value[key]) === index,
    );
  } else {
    return this.filter((value, index, self) => self.indexOf(value) === index);
  }
};
