import { ResponseBody } from "redux-query";

import TransformationLib from "./transformation-lib";

export const transformResponse = <T = any>(
  responseBody: ResponseBody,
  resourceName: string,
): T => {
  if (responseBody && Array.isArray(responseBody[resourceName])) {
    return responseBody[resourceName].map((item) =>
      TransformationLib.convertObjectSnakeCaseToCamelCase(item),
    );
  } else if (responseBody && responseBody[resourceName]) {
    return TransformationLib.convertObjectSnakeCaseToCamelCase(
      responseBody[resourceName],
    );
  } else if (responseBody && responseBody.error) {
    return TransformationLib.convertObjectSnakeCaseToCamelCase(responseBody);
  }
};
