import { Dialog, makeStyles } from "@material-ui/core";
import React, { FunctionComponent, useState } from "react";
import { useSelector } from "react-redux";
import { Icon, Menu } from "semantic-ui-react";
import { searchInDocument } from "../api/search";
import { selectClientColor } from "../selectors/entity";

import { Meta, Search, SearchResult } from "./Search";

interface Props {
  documentId: string;
  school: string;
  fullscreen?: boolean;
  onClick?: () => void;
}
const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
  },
});

const DocumentSearchButton: FunctionComponent<Props> = ({
  documentId,
  school,
  fullscreen = false,
  onClick,
}) => {
  const color = useSelector(selectClientColor);
  const [open, setOpen] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [meta, setMeta] = useState<Meta>();
  const classes = useStyles();

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
    setOpen(true);
  };

  const handleSearch = async (searchTerm) => {
    if (searchTerm) {
      const { data, meta } = await searchInDocument(
        school,
        documentId,
        searchTerm,
      );
      setSearchResults(data);
      setMeta(meta);
    } else {
      setSearchResults([]);
      setMeta(null);
    }
  };

  const handleSelectItem = (item: SearchResult) => {
    setOpen(false);
  };

  const buildLink = (item: SearchResult) => {
    return `#${item.templateNodeId}`;
  };

  return (
    <>
      <Menu.Item onClick={handleOnClick} as="a" style={{ color }}>
        <Icon name="search" /> Zoeken
      </Menu.Item>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth={true}
        fullScreen={fullscreen}
        scroll="paper"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
      >
        <Search
          onSearch={handleSearch}
          searchResults={searchResults}
          meta={meta}
          onSelectItem={handleSelectItem}
          buildLink={buildLink}
          fullscreen={fullscreen}
          onClose={() => setOpen(false)}
        />
      </Dialog>
    </>
  );
};

export default DocumentSearchButton;
