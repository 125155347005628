import React, { FunctionComponent } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Store } from "redux";
import { Provider as ReduxQueryProvider } from "redux-query-react";

import { State } from "../reducers";
import { selectQueries } from "../selectors/query";
import AppRouter from "./AppRouter";

interface Props {
  store: Store<State>;
}

const getBasename = (path) => path.substr(0, path.lastIndexOf("/"));

const App: FunctionComponent<Props> = ({ store }) => (
  <Provider store={store}>
    <ReduxQueryProvider queriesSelector={selectQueries}>
      <BrowserRouter basename={getBasename(window.location.pathname)}>
        <AppRouter />
      </BrowserRouter>
    </ReduxQueryProvider>
  </Provider>
);

export default App;
