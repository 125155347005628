export enum Client {
  Avans = "avans",
  Demo = "demo",
  Han = "han",
  Hu = "hu",
  Hr = "hr",
  Hva = "hva",
  Inholland = "inholland",
  Ru = "ru",
  Tue = "tue",
  Viaa = "viaa",
  Zuyd = "zuyd",
}
