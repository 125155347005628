import React, { FunctionComponent } from "react";
import Lightbox from "react-image-lightbox";
import { useSelector } from "react-redux";

import useToggle from "../../hooks/useToggle";
import { selectImageById } from "../../selectors/entity";

interface Props {
  id: string;
}

const Image: FunctionComponent<Props> = ({ id }) => {
  const [isOpen, open, close] = useToggle(false);
  const image = useSelector((state) => selectImageById(state, id));

  if (image) {
    return (
      <>
        <a onClick={open}>
          <img
            src={image.imageUrl}
            style={{
              cursor: "pointer",
              maxWidth: "100%",
            }}
          />
        </a>
        {isOpen && <Lightbox mainSrc={image.imageUrl} onCloseRequest={close} />}
      </>
    );
  }

  return null;
};

export default Image;
