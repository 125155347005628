import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useMemo,
} from "react";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { Button, Menu, Tab } from "semantic-ui-react";

import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { setIsSidebarOpen } from "../../actions/ui";
import { PublicDocument } from "../../models/public-document";
import { State } from "../../reducers";
import {
  selectDocumentById,
  selectClient as selectClientDetails,
} from "../../selectors/entity";
import {
  selectClient,
  selectClientName,
  selectIsSidebarOpen,
} from "../../selectors/ui";
import ClientLogo from "../ClientLogo";
import DocumentDownloadButton from "../DocumentDownloadButton";
import PageTitle from "./PageTitle";
import { Client } from "../../models/client";
import DocumentSearchButton from "../DocumentSearchButton";
import { Desktop, Mobile } from "../Media";

const logoOerWrite = require("../../images/logo.png");

interface Params {
  documentId?: string;
}

interface Props extends RouteComponentProps<Params> {}

interface StateProps {
  client?: Client;
  document?: PublicDocument;
  isSidebarOpen: boolean;
  school?: string;
  schoolName?: string;
}

interface DispatchProps {
  setIsSidebarOpen: typeof setIsSidebarOpen;
}

type AppMenuProps = Props & StateProps & DispatchProps;

const AppMenu: FunctionComponent<AppMenuProps> = ({
  client,
  document,
  isSidebarOpen,
  match,
  school,
  schoolName,
  setIsSidebarOpen,
}) => {
  const { documentId } = match.params;
  const documentTitle = document && document.title;
  const pageTitle = useMemo(() => {
    if (school && documentId) {
      return documentTitle || "Document";
    } else if (school) {
      return (client.publicReaderSetting || {}).pageTitle;
    }
  }, [document, documentId, school, schoolName, client]);
  const handleClickSidebarToggle = useCallback(
    (e): void => {
      setIsSidebarOpen(!isSidebarOpen);
    },
    [isSidebarOpen, setIsSidebarOpen],
  );

  return (
    <Menu borderless={true} fixed="top" style={{ zIndex: 1000 }}>
      <PageTitle title={pageTitle} clientName={client.name} />
      <Desktop>
        {!school && (
          <Menu.Item>
            <img src={logoOerWrite} className="logo" alt="OER Write" />
          </Menu.Item>
        )}
        {school && (
          <Link className="item" to="/">
            <ClientLogo alt={schoolName} className="logo" client={school} />
          </Link>
        )}
        {school && documentId && (
          <Fragment>
            <Menu.Item
              style={{
                display: "block",
                left: 120,
                right: 120,
                position: "absolute",
                textAlign: "center",
              }}
            >
              <strong>{documentTitle}</strong>
            </Menu.Item>
            <Menu.Menu position="right">
              {client.elasticSearchEnabled && (
                <DocumentSearchButton documentId={documentId} school={school} />
              )}
              <DocumentDownloadButton documentId={documentId} school={school} />
            </Menu.Menu>
          </Fragment>
        )}
      </Desktop>
      <Mobile>
        {!school && (
          <Menu.Item>
            <img src={logoOerWrite} className="logo" alt="OER Write" />
          </Menu.Item>
        )}
        {school && !documentId && (
          <Menu.Item>
            <ClientLogo alt={schoolName} className="logo" client={school} />
          </Menu.Item>
        )}
        {school && documentId && (
          <Fragment>
            <Menu.Item>
              <Button
                icon="bars"
                active={isSidebarOpen}
                basic={true}
                onClick={handleClickSidebarToggle}
              />
            </Menu.Item>
            <Menu.Item fitted="horizontally">{documentTitle}</Menu.Item>
          </Fragment>
        )}
      </Mobile>
    </Menu>
  );
};

const mapStateToProps = (state: State, ownProps: Props): StateProps => {
  const { documentId } = ownProps.match.params;
  const school = selectClient(state);
  const schoolName = selectClientName(state);
  const isSidebarOpen = selectIsSidebarOpen(state);
  const client: Client = selectClientDetails(state);
  let document: PublicDocument;

  if (documentId) {
    document = selectDocumentById(state, documentId);
  }

  return {
    client,
    document,
    isSidebarOpen,
    school,
    schoolName,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators({ setIsSidebarOpen }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AppMenu);
