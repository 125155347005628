import React, { FunctionComponent, useState } from "react";
import Meta from "../models/meta";
import SearchResult from "../models/search-result";
import NoMatches from "./NoMatches";
import SearchBottomToolbar from "./SearchBottomToolbar";
import SearchList from "./SearchList";
import SearchTopToolbar from "./SearchTopToolbar";

interface Props {
  onSearch: (searchTerm: string) => void;
  searchResults: SearchResult[];
  meta: Meta;
  onSelectItem?: (item: SearchResult) => void;
  buildLink?: (item: SearchResult) => string;
  fullscreen?: boolean;
  onClose?: () => void;
}

const Search: FunctionComponent<Props> = ({
  onSearch,
  searchResults,
  onSelectItem,
  meta,
  buildLink,
  onClose,
  fullscreen,
}) => {
  const hasMatches = meta && meta.totalCount > 0;

  return (
    <>
      <SearchTopToolbar
        onSearch={onSearch}
        onClose={onClose}
        fullscreen={fullscreen}
      />
      {meta &&
        (hasMatches ? (
          <SearchList
            searchResults={searchResults}
            onSelectItem={onSelectItem}
            buildLink={buildLink}
          />
        ) : (
          <NoMatches />
        ))}
      {meta && <SearchBottomToolbar matches={meta.totalCount} />}
    </>
  );
};

export default Search;
