import React, { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { QueryConfig } from "redux-query";
import { useRequest } from "redux-query-react";

import { setClient } from "../actions/ui";
import { Client } from "../enums/client";
import { fetchAllDocument } from "../queries/document";
import { selectClient } from "../selectors/ui";

const determineClientByHostname = (): Client => {
  const tenant = location.hostname.split(".")[0];

  if (Object.values(Client).includes(tenant as Client)) {
    return tenant as Client;
  }

  switch (location.hostname) {
    case "oer.inholland.nl":
      return Client.Inholland;
    case "oer.zuyd.nl":
      return Client.Zuyd;
    case "oer.han.nl":
      return Client.Han;
    case "studiegids.hu.nl":
      return Client.Hu;
    case "oer.hr.nl":
      return Client.Hr;
    default:
      return null;
  }
};

const ClientLoader: FunctionComponent = () => {
  const dispatch = useDispatch();
  const school = useSelector(selectClient);
  let request: QueryConfig;

  if (school) {
    request = fetchAllDocument(school);
  } else if (process.env.OERWRITE_CLIENT) {
    const client = process.env.OERWRITE_CLIENT as Client;

    dispatch(setClient(client));
  } else {
    const client = determineClientByHostname();

    if (client) {
      dispatch(setClient(client));
    }
  }

  useRequest(request);

  return null;
};

export default ClientLoader;
