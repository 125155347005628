import React, { PureComponent } from "react";
import TransformationLib from "../../lib/transformation-lib";

interface Props {
  title?: string;
  clientName?: string;
}

class PageTitle extends PureComponent<Props> {
  private static defaultTitle = "OER Write";

  public componentDidMount(): void {
    this.updateTitle();
  }

  public componentDidUpdate(): void {
    this.updateTitle();
  }

  public componentWillUnmount(): void {
    document.title = PageTitle.defaultTitle;
  }

  private updateTitle = (): void => {
    const { title, clientName } = this.props;
    const titleParts = [];

    if (title) {
      titleParts.push(title);
    }

    titleParts.push(
      clientName
        ? TransformationLib.capitalize(clientName)
        : PageTitle.defaultTitle,
    );

    document.title = titleParts.join(" · ");
  };

  public render(): React.ReactNode {
    return null;
  }
}

export default PageTitle;
