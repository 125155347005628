import { Programme } from "./programme";

export interface PublicDocument {
  id: string;
  academicYear: string;
  title: string;
  templateName: string;
  createdAt: string;
  updatedAt: string;
  programmes: Programme[];
  language: "de" | "en" | "nl";
}

export function getLanguageName(language: string) {
  const languages = {
    de: "German",
    en: "English",
    nl: "Dutch",
  };

  return languages[language] || "";
}
