import { useEffect } from "react";

const useScrollToHash = (imagesLoaded) => {
  useEffect(() => {
    const { hash } = window.location;

    if (hash && imagesLoaded) {
      const id = hash.substring(1);
      const element = document.getElementById(id);

      if (element) {
        element.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    }
  }, [imagesLoaded]);
};

export default useScrollToHash;
