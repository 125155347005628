// @ts-ignore
import { DomElement } from "domhandler";
import { Parser, ProcessNodeDefinitions } from "html-to-react";
import React, { FunctionComponent, memo } from "react";

import { HtmlNodeName } from "../../enums/html-node-name";
import Heading from "./Heading";
import Image from "./Image";

const HEADING_LEVEL_MAP = {
  [HtmlNodeName.h1]: 1,
  [HtmlNodeName.h2]: 2,
  [HtmlNodeName.h3]: 3,
};
const NODE_TYPE_TEXT = "text";

const processNodeDefinitions = new ProcessNodeDefinitions(React);
const isDescendantTableTag = (
  parent: DomElement,
  node: DomElement,
): boolean => {
  const descendants: Record<string, string[]> = {
    table: ["colgroup", "thead", "tbody"],
    colgroup: ["col"],
    thead: ["tr"],
    tbody: ["tr"],
    tr: ["th", "td"],
  };

  if (!parent.name || !node.name) {
    return false;
  }

  return (descendants[parent.name] || []).indexOf(node.name) >= 0;
};
const processingInstructions = [
  {
    // Implement our Heading component for h1, h2, and h3
    shouldProcessNode: (node: DomElement) =>
      node.name &&
      Object.keys(HEADING_LEVEL_MAP).includes(node.name) &&
      node.children &&
      node.children.length === 1 &&
      node.children[0].type === NODE_TYPE_TEXT,
    processNode: (node: DomElement, children: DomElement[], index: number) => {
      const level = HEADING_LEVEL_MAP[node.name];
      const text = node.children[0].data;

      return <Heading key={index} text={text} level={level} id={node.attribs.id} />;
    },
  },
  {
    // Implement our Heading component for div.h1, div.h2, and div.h3
    shouldProcessNode: (node: DomElement) =>
      node.name === HtmlNodeName.div &&
      Object.keys(HEADING_LEVEL_MAP).includes(node.attribs["class"]) &&
      node.children &&
      node.children.length === 1 &&
      node.children[0].type === NODE_TYPE_TEXT,
    processNode: (node: DomElement, children: DomElement[], index: number) => {
      const level = HEADING_LEVEL_MAP[node.attribs["class"]];
      const text = node.children[0].data;

      return <Heading key={index} text={text} level={level} />;
    },
  },
  {
    // Add target=_blank to all anchors in the content
    shouldProcessNode: (node: DomElement) => node.name === HtmlNodeName.a,
    processNode: (node: DomElement, children: DomElement[], index: number) => {
      if (node.attribs["href"] && node.attribs["href"].startsWith("#")) {
        // Skip internal anchors
      } else {
        node.attribs["target"] = "_blank";
      }

      return processNodeDefinitions.processDefaultNode(node, children, index);
    },
  },
  {
    // Parse images
    shouldProcessNode: (node: DomElement) =>
      node.name === HtmlNodeName.img && node.attribs["data-image"],
    processNode: (node: DomElement) => {
      const imageId = node.attribs["data-image"];

      return <Image id={imageId} />;
    },
  },
  {
    // Fix whitespace issue in tables
    // https://github.com/aknuds1/html-to-react/issues/79#issuecomment-481913983
    shouldProcessNode: (node: DomElement) => {
      if (node.type === "text" && node.parent) {
        if (node.next) {
          return isDescendantTableTag(node.parent, node.next);
        }

        if (node.prev) {
          return isDescendantTableTag(node.parent, node.prev);
        }
      }

      return false;
    },
    processNode: () => null,
  },
  {
    // Add Semantic-UI classes to tables
    shouldProcessNode: (node: DomElement) => node.name === HtmlNodeName.table,
    processNode: (node: DomElement, children: DomElement[], index: number) => {
      node.attribs["class"] = "ui celled table unstackable";
      node.attribs["style"] = undefined;

      if (node.parent && node.parent !== HtmlNodeName.figure) {
        return (
          <figure className="table">
            {processNodeDefinitions.processDefaultNode(node, children, index)}
          </figure>
        );
      }

      return processNodeDefinitions.processDefaultNode(node, children, index);
    },
  },
  {
    // Remove Nokogiri (debug) data, see T3835
    shouldProcessNode: (node: DomElement) =>
      node.type === NODE_TYPE_TEXT &&
      node.data.trim().startsWith("[#<Nokogiri"),
    processNode: () => null,
  },
  {
    // Anything else
    shouldProcessNode: () => true,
    processNode: processNodeDefinitions.processDefaultNode,
  },
];

interface Props {
  html: string;
}

const ChapterContent: FunctionComponent<Props> = ({ html }) => {
  const htmlToReactParser = new Parser();

  return htmlToReactParser.parseWithInstructions(
    html,
    () => true,
    processingInstructions,
  );
};

export default memo(ChapterContent);
