import React, { FunctionComponent } from "react";

import { Client } from "../enums/client";

const CLIENT_LOGO_MAP = {
  [Client.Avans]: require("../images/clients/avans.svg"),
  [Client.Han]: require("../images/clients/han.svg"),
  [Client.Hu]: require("../images/clients/hu.svg"),
  [Client.Hr]: require("../images/clients/hr.svg"),
  [Client.Hva]: require("../images/clients/hva.svg"),
  [Client.Inholland]: require("../images/clients/inholland.svg"),
  [Client.Ru]: require("../images/clients/ru.svg"),
  [Client.Zuyd]: require("../images/clients/zuyd.svg"),
  [Client.Demo]: require("../images/clients/demo.png"),
};

interface Props {
  alt: string;
  className?: string;
  client: Client | string;
}

const ClientLogo: FunctionComponent<Props> = ({ alt, className, client }) => {
  const logo = CLIENT_LOGO_MAP[client];

  if (logo) {
    return <img alt={alt} className={className} src={logo} />;
  }

  return null;
};

export default ClientLogo;
