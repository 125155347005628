import React, { ComponentType, FunctionComponent, useState } from "react";
import OnImagesLoaded from "react-on-images-loaded";

import useScrollToHash from "../hooks/useScrollToHash";

const withScrollToHash = <P extends {}>(Component: ComponentType<P>) => {
  const WithScrollToHash: FunctionComponent<P> = (props) => {
    const [imagesLoaded, setImagesLoaded] = useState(false);
    useScrollToHash(imagesLoaded);

    return (
      <OnImagesLoaded
        onLoaded={() => {
          setImagesLoaded(true);
        }}
      >
        <Component {...props} />
      </OnImagesLoaded>
    );
  };

  return WithScrollToHash;
};

export default withScrollToHash;
