import React, { FunctionComponent, SyntheticEvent, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Sidebar } from "semantic-ui-react";

import { setIsSidebarOpen } from "../../actions/ui";
import DocumentStructureLib from "../../lib/document-structure-lib";
import { selectIsSidebarOpen } from "../../selectors/ui";
import ChapterList from "./ChapterList/ChapterList";

interface Props {
  documentId: string;
  documentStructure: DocumentStructureLib;
  indexChapterActive: number;
  onChangeIndexChapterActive: (index: number) => void;
}

const ChapterListSidebar: FunctionComponent<Props> = ({
  documentId,
  documentStructure,
  indexChapterActive,
  onChangeIndexChapterActive,
}) => {
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector(selectIsSidebarOpen);

  return (
    <Sidebar
      direction="left"
      style={{
        paddingTop: 49,
      }}
      visible={isSidebarOpen}
    >
      <ChapterList
        borderless={true}
        documentId={documentId}
        documentStructureChapters={documentStructure.getStructure()}
        indexChapterActive={indexChapterActive}
        onChangeIndexChapterActive={onChangeIndexChapterActive}
      />
    </Sidebar>
  );
};

export default ChapterListSidebar;
