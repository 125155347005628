import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";

import { selectClient } from "../selectors/ui";
import AppMenu from "./AppMenu";
import ClientList from "./ClientList";
import ClientLoader from "./ClientLoader";
import DocumentList from "./DocumentList";
import DocumentView from "./DocumentView";
import ScrollToTop from "./ScrollToTop";

interface Props {}

const AppRouter: FunctionComponent<Props> = () => {
  const school = useSelector(selectClient);

  return (
    <ScrollToTop>
      <Route path="/" component={ClientLoader} />
      <Route path="/:documentId?/" component={AppMenu} />

      <div style={{ paddingTop: 64 }}>
        <Switch>
          {!school && <Route path="/" component={ClientList} />}

          <Route exact={true} path="/" component={DocumentList} />
          <Route exact={true} path="/:documentId/" component={DocumentView} />
        </Switch>
      </div>
    </ScrollToTop>
  );
};

export default AppRouter;
