import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Icon, Menu } from "semantic-ui-react";

import { selectAttachmentsByDocumentId } from "../../../selectors/entity";

interface Props {}

const AttachmentList: FunctionComponent<Props> = () => {
  const { documentId } = useParams();
  const attachments = useSelector((state) =>
    selectAttachmentsByDocumentId(state, documentId),
  );

  if (!attachments.length) {
    return null;
  }

  return (
    <>
      <Menu.Item header={true}>
        {attachments.length === 1 ? "Bijlage" : "Bijlagen"}
      </Menu.Item>
      {attachments.map((attachment) => (
        <Menu.Item
          key={attachment.id}
          as="a"
          className="borderless chapter-item"
          href={attachment.fileUrl}
          target="_blank"
        >
          <Icon name="external" />
          {attachment.name}
        </Menu.Item>
      ))}
    </>
  );
};

export default AttachmentList;
