import { combineReducers } from "redux";
import { queriesReducer } from "redux-query";

import entity from "./entity";
import ui from "./ui";

const rootReducer = combineReducers({
  entity,
  query: queriesReducer,
  ui,
});

export type State = ReturnType<typeof rootReducer>;

export default rootReducer;
