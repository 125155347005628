import React from "react";
import { useSelector } from "react-redux";
import { Icon } from "semantic-ui-react";
import { selectClientColor } from "../../../selectors/entity";

interface Props {
  visibility: boolean;
  onClick: () => void;
}

export const DocumentFilterSwitch = ({ visibility, onClick }: Props) => {
  const color = useSelector(selectClientColor);

  return (
    <div onClick={onClick} style={{ color }}>
      {visibility ? <Icon name="caret down" /> : <Icon name="caret right" />}
      Documentlijstfilters
    </div>
  );
};
