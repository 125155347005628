import axios from "axios";
import { Meta, SearchResult } from "../components/Search";
import { SearchObjectType } from "../components/Search/models/search-object-type";
import env from "../constants";
import { transformResponse } from "../lib/query-lib";

export const searchInDocument = async (client, documentId, searchTerm) => {
  const response = await axios.request({
    url: `${env(client).ApiUrl}/api/public/search`,
    method: "GET",
    params: {
      "filter[document_id]": documentId,
      "terms[object_type]": [
        SearchObjectType.ContentArea,
        SearchObjectType.Chapter,
        SearchObjectType.Heading,
        SearchObjectType.Attachment,
      ],
      query: searchTerm,
    },
  });

  if (response && response.data) {
    const data = transformResponse<SearchResult[]>(response.data, "result");
    const meta = transformResponse<Meta>(response.data, "meta");

    return { data, meta };
  }
};
