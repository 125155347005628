export default class TransformationLib {
  public static convertCamelCaseToSnakeCase = (camelCase: string) =>
    camelCase.replace(/([A-Z])/g, (matches) => `_${matches[0].toLowerCase()}`);

  public static convertSnakeCaseToCamelCase = (snakeCase: string) =>
    snakeCase.replace(/(_\w)/g, (matches) => matches[1].toUpperCase());

  public static convertObjectCamelCaseToSnakeCase = <T>(original: object): T =>
    TransformationLib.convertObjectKeys<T>(
      original,
      TransformationLib.convertCamelCaseToSnakeCase,
    );

  public static convertObjectSnakeCaseToCamelCase = <T>(original: object): T =>
    TransformationLib.convertObjectKeys<T>(
      original,
      TransformationLib.convertSnakeCaseToCamelCase,
    );

  public static capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  private static convertObjectKeys = <T>(
    original: object | any[],
    transformer: Function,
  ): any => {
    if (Array.isArray(original)) {
      return original.map((item) =>
        TransformationLib.convertObjectKeys(item, transformer),
      );
    } else if (typeof original === "object" && original !== null) {
      const result = {} as T;

      Object.keys(original).forEach((keyOriginal) => {
        const keyTransformed = transformer(keyOriginal);
        const valueOriginal = original[keyOriginal];

        result[keyTransformed] = TransformationLib.convertObjectKeys(
          valueOriginal,
          transformer,
        );
      });

      return result;
    } else {
      return original;
    }
  };
}
