import { useMediaQuery } from "react-responsive";

export const useDesktopMediaQuery = () =>
  useMediaQuery({ query: "(min-width: 1024px)" });

export const useMobileMediaQuery = () =>
  useMediaQuery({ query: "(max-width: 1024px)" });

export const Desktop = ({ children }) => {
  const isDesktop = useDesktopMediaQuery();

  return isDesktop ? children : null;
};

export const Mobile = ({ children }) => {
  const isMobile = useMobileMediaQuery();

  return isMobile ? children : null;
};
