interface AppConstants {
  ApiUrl: string;
}

const env = (client: string): AppConstants => {
  return {
    ApiUrl: `${process.env.API_PROTOCOL}://${client}.${process.env.API_DOMAIN}`,
  };
};

export default env;
