import { useCallback, useState } from "react";

type EnableFn = () => void;
type DisableFn = () => void;
type ToggleFn = () => void;

type Toggle = [boolean, EnableFn, DisableFn, ToggleFn];

const useToggle = (defaultValue = false): Toggle => {
  const [isEnabled, setIsEnabled] = useState(defaultValue);
  const disable = useCallback(() => setIsEnabled(false), [setIsEnabled]);
  const enable = useCallback(() => setIsEnabled(true), [setIsEnabled]);
  const toggle = useCallback(() => setIsEnabled(prevState => !prevState), [
    setIsEnabled,
  ]);

  return [isEnabled, enable, disable, toggle];
};

export default useToggle;
