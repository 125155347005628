export enum HtmlNodeName {
  a = "a",
  div = "div",
  figure = "figure",
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  img = "img",
  table = "table",
}
