import React, {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  FC,
  SyntheticEvent,
} from "react";
import { selectClient } from "../../../selectors/entity";

import { Button, Input, Dropdown, DropdownItemProps } from "semantic-ui-react";
import usePrevious from "../../../hooks/usePrevious";
import { DocumentFilter } from "../../../models/document-filter";
import AcademySelector from "../AcademySelector";
import { useSelector } from "react-redux";
import { Client } from "../../../models/client";

interface Props {
  filter: DocumentFilter;
  onChange: (filter: DocumentFilter) => void;
  uniqueAcademicYears: string[];
  programmes: DropdownItemProps[];
  programmeStudentTitles: DropdownItemProps[];
  academies: string[];
}

const Desktop: FC<Props> = ({
  onChange,
  filter,
  uniqueAcademicYears,
  programmes,
  programmeStudentTitles,
  academies,
}) => {
  const client = useSelector(selectClient) as Client;

  const handleClickAcademy = (academy: string) => {
    onChange({ ...filter, academy });
  };
  const handleChangeAcademicYear = (event: SyntheticEvent, { value }) => {
    onChange({ ...filter, academicYear: value });
  };

  const handleChangeProgramme = (event: SyntheticEvent, { value }) => {
    onChange({ ...filter, programmeId: value });
  };

  const handleChangeProgrammeStudentTitles = (
    event: SyntheticEvent,
    { value },
  ) => {
    onChange({ ...filter, programmeStudentTitleId: value });
  };

  const handleChangeFilter = (event: ChangeEvent<HTMLInputElement>): void => {
    const searchTerm = event.target.value;
    event.preventDefault();
    onChange({ ...filter, searchTerm });
  };

  const handleClickClear = (event: MouseEvent): void => {
    event.preventDefault();
    onChange({ ...filter, searchTerm: "" });
  };

  const handleKeyUp = (event: KeyboardEvent): void => {
    if (event.key === "Escape") {
      onChange({ ...filter, searchTerm: "" });
    }
  };
  const previousAcademicYear = usePrevious(filter.academicYear);

  useEffect(() => {
    if (
      uniqueAcademicYears.length > 1 &&
      uniqueAcademicYears.includes(filter.academicYear) &&
      previousAcademicYear !== filter.academicYear
    ) {
      onChange({ ...filter, academicYear: filter.academicYear });
    }
  }, [uniqueAcademicYears, filter, previousAcademicYear]);
  return (
    <>
      {client?.publicReaderSetting?.groupDocumentsByAcademy && (
        <AcademySelector
          academies={academies}
          selected={filter.academy}
          onClick={handleClickAcademy}
        />
      )}
      <div style={{ marginBottom: 10, display: "flex", gap: 5 }}>
        <Dropdown
          selection
          options={uniqueAcademicYears.map((a) => ({ text: a, value: a }))}
          value={filter.academicYear}
          onChange={handleChangeAcademicYear}
        />
        {programmeStudentTitles && (
          <Dropdown
            selection
            options={programmeStudentTitles}
            value={filter.programmeStudentTitleId}
            onChange={handleChangeProgrammeStudentTitles}
          />
        )}
        {programmes && (
          <Dropdown
            selection
            options={programmes}
            value={filter.programmeId}
            onChange={handleChangeProgramme}
          />
        )}

        <Input
          icon="search"
          placeholder="Zoeken..."
          value={filter.searchTerm}
          onChange={handleChangeFilter}
          onKeyUp={handleKeyUp}
        />
        {filter.searchTerm.length > 0 && (
          <Button circular={true} icon="close" onClick={handleClickClear} />
        )}
      </div>
    </>
  );
};

export default Desktop;
