import React, { FunctionComponent } from "react";
import { genericHashLink } from "react-router-hash-link";
import { Accordion, Menu } from "semantic-ui-react";

import DocumentStructureLib from "../../../lib/document-structure-lib";
import { DocumentStructureChapter } from "../../../models/document-structure-chapter";

const MenuItemLink = genericHashLink(Menu.Item);

interface Props {
  chapterIndex: number;
  chapter: DocumentStructureChapter;
  colorIndicator: string;
  isChapterActive: boolean;
  onClickChapter: (event, itemProps) => void;
  onClickParagraph: () => void;
}

const ChapterItem: FunctionComponent<Props> = ({
  chapter,
  chapterIndex,
  colorIndicator,
  isChapterActive,
  onClickChapter,
  onClickParagraph,
}) => {
  return (
    <Menu.Item className="borderless chapter-item">
      {isChapterActive && (
        <div
          className="indicator-active"
          style={{ backgroundColor: colorIndicator }}
        />
      )}
      <Accordion.Title
        active={isChapterActive}
        className="text-overflow-ellipsis"
        content={chapter.title}
        index={chapterIndex}
        onClick={onClickChapter}
      />
      <Accordion.Content active={isChapterActive} className="menu">
        {chapter.paragraphs.map((paragraph, indexParagraph) => (
          <MenuItemLink
            key={indexParagraph}
            className="text-overflow-ellipsis"
            to={`#${DocumentStructureLib.slugify(paragraph)}`}
            onClick={onClickParagraph}
          >
            {paragraph}
          </MenuItemLink>
        ))}
      </Accordion.Content>
    </Menu.Item>
  );
};

export default ChapterItem;
