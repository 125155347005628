import React, {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  FC,
  useState,
  useEffect,
} from "react";
import { useSelector } from "react-redux";
import { Input, Dropdown, DropdownItemProps, Icon } from "semantic-ui-react";
import usePrevious from "../../../hooks/usePrevious";
import { Client } from "../../../models/client";
import { DocumentFilter } from "../../../models/document-filter";
import { selectClient } from "../../../selectors/entity";
import { DocumentFilterSwitch } from "./DocumentFilterSwitch";

interface Props {
  filter: DocumentFilter;
  onChange: (filter: DocumentFilter) => void;
  uniqueAcademicYears: string[];
  programmes: DropdownItemProps[];
  programmeStudentTitles: DropdownItemProps[];
  academies: string[];
}

const Mobile: FC<Props> = ({
  onChange,
  filter,
  uniqueAcademicYears,
  programmes,
  programmeStudentTitles,
  academies,
}) => {
  const client = useSelector(selectClient) as Client;

  const [filterVisibility, setFilterVisibility] = useState(false);
  const handleChangeFilter = (event: ChangeEvent<HTMLInputElement>): void => {
    const searchTerm = event.target.value;
    event.preventDefault();
    onChange({ ...filter, searchTerm });
  };

  const handleClickClear = (event: MouseEvent): void => {
    event.preventDefault();
    onChange({ ...filter, searchTerm: "" });
  };

  const handleKeyUp = (event: KeyboardEvent): void => {
    if (event.key === "Escape") {
      onChange({ ...filter, searchTerm: "" });
    }
  };

  const previousAcademicYear = usePrevious(filter.academicYear);

  useEffect(() => {
    if (
      uniqueAcademicYears.length > 1 &&
      uniqueAcademicYears.includes(filter.academicYear) &&
      previousAcademicYear !== filter.academicYear
    ) {
      onChange({ ...filter, academicYear: filter.academicYear });
    }
  }, [uniqueAcademicYears, filter, previousAcademicYear]);
  return (
    <>
      <div style={{ marginBottom: 10 }}>
        <Input
          placeholder="Zoeken..."
          value={filter.searchTerm}
          onChange={handleChangeFilter}
          onKeyUp={handleKeyUp}
          icon={
            filter.searchTerm.length > 0 ? (
              <Icon name="close" link onClick={handleClickClear} />
            ) : (
              <Icon name="search" />
            )
          }
          style={{ width: "100%" }}
        />
      </div>
      <DocumentFilterSwitch
        visibility={filterVisibility}
        onClick={() => setFilterVisibility(!filterVisibility)}
      />

      {filterVisibility && (
        <div
          style={{
            marginTop: 10,
            marginBottom: 10,
            display: "grid",
            gap: 5,
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          {academies && client?.publicReaderSetting?.groupDocumentsByAcademy && (
            <Dropdown
              className="filter"
              selection
              options={academies.map((a) => ({ text: a, value: a }))}
              value={filter.academy}
              onChange={(_, { value }) =>
                onChange({ ...filter, academy: value as string })
              }
            />
          )}

          {uniqueAcademicYears && (
            <Dropdown
              selection
              options={uniqueAcademicYears.map((a) => ({ text: a, value: a }))}
              value={filter.academicYear}
              onChange={(_, { value }) =>
                onChange({ ...filter, academicYear: value as string })
              }
              className="filter"
            />
          )}
          {programmeStudentTitles && (
            <Dropdown
              selection
              options={programmeStudentTitles}
              value={filter.programmeStudentTitleId}
              onChange={(_, { value }) =>
                onChange({
                  ...filter,
                  programmeStudentTitleId: value as string,
                })
              }
              className="filter"
            />
          )}
          {programmes && (
            <Dropdown
              selection
              options={programmes}
              value={filter.programmeId}
              onChange={(_, { value }) =>
                onChange({ ...filter, programmeId: value as string })
              }
              className="filter"
            />
          )}
        </div>
      )}
    </>
  );
};

export default Mobile;
